import * as React from "react";
import { Row, Col, Card, Divider, Typography, Button, Tooltip } from "antd";
import { graphql, Link } from "gatsby";
import HeaderComp from "../components/header";
import "../components/layout.css";
import HomeJsonData from "../data/home.json";
import FooterComp from "../components/footer";

const { Meta } = Card;
const { Title } = Typography;

const EnterpriseManagement = ({ data }) => {
  let enterpriseData = data.allSitePage.edges[0].node.context;
  console.log('enterpriseData', enterpriseData);
  return (
    <>
      <HeaderComp />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1920,
        }}
      >
        <div className={enterpriseData.imageClass}>
          <Row className="enterpriseTitleRow">
            <Col span={24}>
              <h1 className="enterpriseTitle">{enterpriseData.name}</h1>
            </Col>
          </Row>
        </div>
        <div style={{ padding: "0 4%" }}>
          <div>
            <div
              data-sal="slide-down"
              data-sal-duration="1000"
              data-sal-delay="1000"
              data-sal-easing="linear"
            >
              <h1 className="enterpriseContentHead">
                {enterpriseData.description.title}
              </h1>
              <p className="enterpriseContentPara">
                {enterpriseData.description.para}
              </p>
            </div>
            <h3 className="enterpriseContentSubHead">
              {enterpriseData.description.subPara}
            </h3>
            <div>
              <Row>
                {enterpriseData.cardData.map((item) => (
                  <Col
                    xs={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{
                      span:
                        enterpriseData.cardData.length == 9 ||
                        enterpriseData.cardData.length == 6 ||
                        enterpriseData.cardData.length == 3
                          ? 8
                          : enterpriseData.cardData.length == 2
                          ? 12
                          : 6,
                    }}
                    data-sal="zoom-in"
                    data-sal-duration="1000"
                    data-sal-delay="1000"
                    data-sal-easing="linear"
                  >
                    <Card
                      bodyStyle={{ paddingTop: 0, paddingLeft: 0 }}
                      className="enterpriseCards"
                      cover={<img src={item.img} />}
                    >
                      <Tooltip title={item.title}>
                        <Meta
                          className="enterpriseCardsTitle"
                          title={item.title}
                        />
                      </Tooltip>
                    </Card>
                  </Col>
                ))}
              </Row>
              <p className="enterpriseCardsPara">{enterpriseData.para}</p>
            </div>
            <Divider />
            <div>
              <Row style={{ width: "100%", margin: "0 auto" }}>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 10 }}
                  lg={{ span: 10 }}
                  style={{
                    margin: "25px 4% 25px 4%",
                    width: "calc(50% - ( ( 4% ) * 0.5 ) )",
                  }}
                  data-sal="slide-right"
                  data-sal-duration="1000"
                  data-sal-delay="1000"
                  data-sal-easing="linear"
                >
                  <img src={HomeJsonData.content5.img} />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  md={{ span: 10 }}
                  lg={{ span: 10 }}
                  style={{
                    marginTop: "25px",
                    marginBottom: "25px",
                    width: "calc(50% - ( ( 4% ) * 0.5 ) )",
                    marginRight: "6%",
                  }}
                  data-sal="slide-left"
                  data-sal-duration="1000"
                  data-sal-delay="1000"
                  data-sal-easing="linear"
                >
                  <div>
                    <Title
                      style={{
                        fontSize: "56px",
                        fontFamily: "sans-serif",
                        fontWeight: 600,
                        fontStyle: "normal",
                      }}
                    >
                      {HomeJsonData.content5.title}
                    </Title>
                    {enterpriseData.name == "Retail" ? (
                      HomeJsonData.content5.retailPara.map((item) => (
                        <p className="content4subPara">
                          {" "}
                          <span
                            style={{
                              color: "#4fc4f4",
                              fontSize: "32px",
                              marginRight: "11.2px",
                              paddingTop: "5px",
                            }}
                          >
                            *
                          </span>
                          <p style={{ color: "#272d3a", fontWeight: 600 }}>
                            {item.name} -{" "}
                            <span style={{ color: "#5c6770", fontWeight: 500 }}>
                              {item.para}
                            </span>
                          </p>
                        </p>
                      ))
                    ) : enterpriseData.name == "Services" ? (
                      HomeJsonData.content5.servicesPara.map((item) => (
                        <p className="content4subPara">
                          <span
                            style={{
                              color: "#4fc4f4",
                              fontSize: "32px",
                              marginRight: "11.2px",
                              paddingTop: "5px",
                            }}
                          >
                            *
                          </span>
                          {item.para}
                        </p>
                      ))
                    ) : (
                      <p
                        style={{
                          fontSize: "20px",
                          lineHeight: "1.6em",
                          color: "#5c6770",
                          fontFamily: "sans-serif",
                          fontWeight: 400,
                          fontStyle: "normal",
                        }}
                      >
                        {HomeJsonData.content5.para}
                      </p>
                    )}
                  </div>
                  <Link to="/contact">
                    <Button type="primary" size="large" className="getStartBtn">
                      Contact us
                    </Button>
                  </Link>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <FooterComp />
    </>
  );
};

export default EnterpriseManagement;

export const query = graphql`
  query($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          context {
            cardData {
              title
              img
            }
            name
            pathUrl
            imageClass
            description {
              title
              para
              subPara
            }
            para
          }
        }
      }
    }
  }
`;
